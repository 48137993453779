export default Object.freeze({
  'coco-bongo-cancun': {
    name: 'Cocobongo Cancun',
    caption: 'Show & Disco',
    logo:
      'https://imgix.cosmicjs.com/f816bad0-6a28-11ef-b5ae-a594bb4a8e67-logo-coco-bongo-cancun.png',
    secondaryColor: '#643a43',
    color: '#c82121',
    video: {
      title: 'Coco Bongo Cancún',
      youtube: 'true',
      content:
        '<p>From Samba Carnival to acrobatic feats, Coco Bongo delivers unforgettable entertainment every night. Don\'t miss out on this iconic Cancún experience!</p>',
      id: 'nEL43Kp5yqo',
      videoUrl: 'https://www.youtube.com/watch?v=nEL43Kp5yqo',
    },
  },
  'confessions-cancun': {
    name: 'Confessions Cancun',
    caption: 'Neon lights & late night beats',
    logo:
      'https://imgix.cosmicjs.com/ff64ead0-7cea-11ef-915f-61acd7484848-confessions-cancun-logo.png',
    secondaryColor: '#643a43',
    color: '#474343',
    video: {
      title: 'Jungle Bar & Neon Nights.',
      youtube: 'true',
      content:
        '<p> ✨The Right Place To Glow In The Dark</p>',
      id: 'at877Poafxg',
      videoUrl: 'https://www.youtube.com/watch?v=at877Poafxg',
    },
  },
  'taboo-cancun': {
    name: 'Taboo Cancun',
    caption: 'Seaside Flavors for a Party and Dinner night.',
    logo:
      'https://imgix.cosmicjs.com/182c9a00-7cd1-11ef-beb8-f3894cda4d77-Captura-de-pantalla-2024-09-27-070258.png',
    secondaryColor: '#c8b496',
    color: '#6e3c29',
    video: {
      title: ' A culinary journey to the Mediterranean.',
      youtube: 'true',
      content:
        '<p> ✨At Taboo Cancun, Mediterranean cuisine comes alive. With classic recipes and an innovative twist, each dish is a unique experience.</p>',
      id: 'XJXxz-rTq5w',
      videoUrl: 'https://www.youtube.com/watch?v=XJXxz-rTq5w',
    },
  },
  'kultura-restaurant-cancun': {
    name: 'Kultura Cancun',
    caption: 'Contemporary greek concept',
    logo:
      'https://imgix.cosmicjs.com/182b8890-7cd1-11ef-beb8-f3894cda4d77-Captura-de-pantalla-2024-09-27-070328.png',
    secondaryColor: '#B58343',
    color: '#444442',
    video: {
      title: 'Savor the Passion in Every Bite',
      youtube: 'true',
      content:
        '<p>Kultura\'s culinary art 👨‍🍳, nature\'s heart,\n' +
        'Each ingredient, a quality part,\n' +
        'Respect for nature 🥬, in every bite,\n' +
        'A taste of goodness, pure delight..</p>',
      id: 'bZikK4KuknI',
      videoUrl: 'https://www.youtube.com/watch?v=bZikK4KuknI',
    },
  },
  'ilios-restaurant-cancun': {
    name: 'Ilios Cancun',
    caption: 'An escapade for a mediterranean-inspired dream.',
    logo:
      'https://imgix.cosmicjs.com/a6a32060-d5a6-11ee-9ce5-59949019255e-logo-ilios-restaurant-tulum.png',
    color: '#b3a8a3',
    secondaryColor: '#513a1f',
    video: {
      title: 'Passion in Every Bite',
      youtube: 'true',
      content:
        '<p>Violins 🎻 play, dancers 💃 sway,\n' +
        'Fire 🔥 ablaze, night and day.</p>',
      id: 'E87amZ_xqhE',
      videoUrl: 'https://www.youtube.com/watch?v=E87amZ_xqhE',
    },
  },
  'rosa-negra-cancun': {
    name: 'Rosa Negra Cancun',
    caption: 'Contemporary greek concept',
    logo:
      'https://imgix.cosmicjs.com/18293ea0-7cd1-11ef-beb8-f3894cda4d77-Captura-de-pantalla-2024-09-27-070147.png',
    color: '#b3a8a3',
    secondaryColor: '#513a1f',
    video: {
      title: 'Savor the Passion in Every Bite',
      youtube: 'true',
      content:
        '<p></p>',
      id: 'E87amZ_xqhE',
      videoUrl: 'https://www.youtube.com/watch?v=E87amZ_xqhE',
    },
  },
  "mun-nightclub-cancun": {
    name: 'Mun NightClub Cancun',
    caption: 'Party to the Mün and Back',
    logo:
      'https://imgix.cosmicjs.com/fc0df4e0-a10b-11ef-b5a0-93db72e2be98-Mun-Night-Club-Logo.jpg',
    color: '#bbc4d6',
    secondaryColor: '#8098bc',

  }
});
