//TODO: COnsolidate with clubsConfig.
// Address can live on the cosmic side.
export default Object.freeze({
  'coco-bongo-cancun': {
    name: 'Coco Bongo Cancun',
    price: 3,
    directions: {
      address:
        'Blvd. Kukulcan 30, Punta Cancun, Zona Hotelera, 77500 Cancún, Q.R.',
      googleMap:
        'pb=!1m18!1m12!1m3!1d10372.389792192842!2d-86.75455744712895!3d21.128124384564536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2bf1d63b6b99%3A0x6cc32e706b78b7bf!2sCoco%20Bongo!5e0!3m2!1ses!2ses!4v1726575274407!5m2!1ses!2ses',
    },
    tags: [
      {label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.'},
      {
        label: 'NIGHT',
        value: '9PM-TIL LATE',
        desc:
          'Filled with energy and life during the night. From 9PM until the late hours. Daily. ',
      },
      {
        label: 'SHOW',
        value: 'DJs/SHOW + PERFORMANCES',
        desc: 'Nightclub vibes and dance floor, also a variety of themed SHOWS and PERFORMANCES. Constantly updated.',
      },
      {
        label: 'MUSIC',
        value: 'DIVERSE',
        desc:
          'Coco Bongo offers a diverse range of music to suit all tastes, which changes depending on the themed shows..',
      },
      {
        label: 'DRESS',
        value: 'PARTY/CASUAL',
        desc:
          'No formal dresscode. DRESS TO PARTY!',
      },
      {
        label: 'GOOD FOR',
        value: 'SIGNATURE CANCUN PARTY',
        desc:
          'Good for everyone ! CocoBongo offers a signature party of Cancun, one that no one should miss!.',
      },
    ],
  },
  'confessions-cancun': {
    name: 'Confessions Cancun',
    price: 5,
    directions: {
      address:
        'Bohemian Square, Blvd. Kukulcan 15, Zona Hotelera, 77500 Cancún, Q.R.',
      googleMap:
        'pb=!1m14!1m8!1m3!1d14890.635004400823!2d-86.7739808!3d21.0862875!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c29956912c271%3A0xdb41787dd9885e5a!2sConfessions%20Cancun!5e0!3m2!1sen!2smx!4v1727457671230!5m2!1sen!2smx',
    },
    tags: [
      {label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.'},
      {
        label: 'NIGHT',
        value: '11PM-TIL LATE',
        desc:
          'The perfect after-party hub from Thursday to Sunday.',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'Nightclub vibes, with a central dance floor and perfect for after-dinner parties.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'International and Local DJ Sets. Mostly House, DJ Repertoire: Meduza, Mr. Pig, Enigma.',
      },
      {
        label: 'DRESS',
        value: 'CHIC',
        desc:
          'Smart Casual (No sportswear/beachwear, and no flipflops).',
      },
      {
        label: 'GOOD FOR',
        value: 'LATE PARTY',
        desc:
          'Confessions Cancun is the place to go if you want to party until the late hours of the night.',
      },
    ],
  },
  "taboo-cancun": {
    name: 'Taboo',
    price: 5,
    directions: {
      address:
        'Blvd. Kukulcan Km 13.5, Zona Hotelera, 77500 Cancún, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3722.3855964145637!2d-86.77139752656376!3d21.0971878854283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c29d9e15b6d13%3A0x8eedf2ad64ca8c03!2sTaboo%20%7C%20Mediterranean%20Restaurant%20in%20Cancun!5e0!3m2!1sen!2ses!4v1727960833685!5m2!1sen!2ses',
    },
    tags: [
      { label: 'DAY', value: '1PM-6PM ', desc: 'Open During The Day.' },
      {
        label: 'NIGHT',
        value: '6PM-TIL LATE',
        desc:
          'From the afternoon until the late hours. Daily. ',
      },
      {
        label: 'SHOW',
        value: 'LIVE SHOWS',
        desc: 'Live Violin performances by The Lagoon.',
      },
      {
        label: 'MUSIC',
        value: 'LATIN/LIVE',
        desc:
          'Latin House and Live Music.',
      },
      {
        label: 'DRESS',
        value: 'BEACH/SMART-ATTIRE',
        desc:
          'Relaxed during the day and more smart-casual towards the night.',
      },
      {
        label: 'GOOD FOR',
        value: 'DINNER AND LIVE SHOW',
        desc:
          'Taboo Cancun is one of the safest options for a Dinner night with an impressive show along with it.',
      },
    ],
  },
  'kultura-restaurant-cancun': {
    name: 'Kultura',
    price: 5,
    directions: {
      address:
        'Blvd. Kukulcan KM 15, Zona Hotelera, 77500 Cancún, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d7445.329007187541!2d-86.77686312656415!3d21.08605788581166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c292e7ff2beff%3A0xa4bffaab0f2a5fff!2sKultura%20Canc%C3%BAn%20%7C%20by%20Athinagoras%20Kostakos!5e0!3m2!1sen!2ses!4v1727960997040!5m2!1sen!2ses',
    },
    tags: [
      { label: 'DAY', value: 'WEEKENDS ONLY', desc: '1PM-5PM.' },
      {
        label: 'NIGHT',
        value: '5PM-TIL LATE',
        desc:
          'Open from the afternoon until the late hours. Daily. ',
      },
      {
        label: 'FOOD',
        value: 'GOURMET GREEK',
        desc: 'Athinagoras Kostakos is a world-class Cheft like very few in Cancun.',
      },
      {
        label: 'MUSIC',
        value: 'SOFT MUSIC',
        desc:
          'Latin, Greek, and modern inspired music for a captivating dinner ambience.',
      },
      {
        label: 'DRESS',
        value: 'SMART-CASUAL',
        desc:
          'Elegant/Smart Casual',
      },
      {
        label: 'GOOD FOR',
        value: 'FINE DINING',
        desc:
          'This new and exciting concept in fine dining marks a remarkable moment on the landscape of Cancun. Taste it by yourself!',
      },
    ],
  },
  'ilios-restaurant-cancun': {
    name: 'Ilios',
    price: 5,
    directions: {
      address:
        'Blvd. Kukulcan Km. 13.5, Zona Hotelera, 77500 Cancún, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d7444.575956614564!2d-86.7694607265636!3d21.10108208529421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c298bdaaba491%3A0x6ff9646e0381532a!2sIlios%20%7C%20Greek%20restaurant%20in%20Cancun!5e0!3m2!1sen!2ses!4v1727960648278!5m2!1sen!2ses',
    },
    tags: [
      { label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.' },
      {
        label: 'NIGHT',
        value: '5PM-TIL LATE',
        desc:
          'Open from the afternoon until the late hours. Daily. ',
      },
      {
        label: 'SHOW',
        value: 'DINNER AND SHOW',
        desc: 'Greek dining experiences with fire shows, violin shows, live music, and plate-smashing..',
      },
      {
        label: 'MUSIC',
        value: 'LATIN HOUSE',
        desc:
          'Latin House dj residents.',
      },
      {
        label: 'DRESS',
        value: 'CHIC',
        desc:
          'Smart Casual Attire',
      },
      {
        label: 'GOOD FOR',
        value: 'DINNER',
        desc:
          'Celebrate a special occasion and a unique Greek dinner.',
      },
    ],
  },
  'rosa-negra-cancun': {
    name: 'Rosa Negra',
    price: 5,
    directions: {
      address:
        'Blvd. Kukulcan Km 15, Zona Hotelera, 77500 Cancún, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3722.688157880924!2d-86.77710262656421!3d21.085113685844085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c28364cf095d9%3A0x4cd06a9ad629d829!2sRosaNegra%20%7C%20Latin%20American%20Restaurant%20in%20Cancun!5e0!3m2!1sen!2ses!4v1727960935150!5m2!1sen!2ses',
    },
    tags: [
      { label: 'DAY', value: '1PM-6PM ', desc: 'Open For Lunch.' },
      {
        label: 'NIGHT',
        value: '6PM-TIL LATE',
        desc:
          'Open from the afternoon until the late hours. Daily. ',
      },
      {
        label: 'SHOW',
        value: 'LIVE FIRE SHOWS',
        desc: 'A professional fire performance inspired in an ancient dance.',
      },
      {
        label: 'MUSIC',
        value: 'LATIN HOUSE',
        desc:
          "Tech & Latin House are the part of the Taboo DJs resident's repertoire.",
      },
      {
        label: 'DRESS',
        value: 'CHIC',
        desc:
          'Casual-elegant (No sportswear/beachwear, and no flipflops).',
      },
      {
        label: 'GOOD FOR',
        value: 'STEAK DINNER + FIRE SHOW',
        desc:
          "Rosa Negra's experience in Cancun is unique. Best enjoyed by having a steak, seafood, or vegan gourmet dinner and attending the various happenings and the fire show.",
      },
    ],
  },

  "mun-nightclub-cancun": {
    name: 'Mun NightClub Cancun',
    price: 3,
    directions: {
      address: 'Blvd. Kukulcan Km 14.7, Zona Hotelera, 77500 Cancún, Q.R.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14890.551856954746!2d-86.7737249!3d21.0871172!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c29dda06d7f71%3A0xe7f00d97da090abe!2sM%C3%9CN%20Nightclub!5e0!3m2!1sen!2smx!4v1731441453812!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '11PM-5AM', desc: 'Open from Thursday to Saturday.'},
      {label: 'SHOW', value: 'DJ SET', desc: 'The perfect spot for late night parties with DJ sets.'},
      {label: 'MUSIC', value: 'TECH/HOUSE', desc: 'International and Local DJ Sets.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Smart Casual (No sportswear/beachwear, and no flipflops).'},
      {
        label: 'GOOD FOR',
        value: 'LATE PARTY',
        desc: 'A great option to dance, socialize and listen to music till late.'
      },
    ],
  }
});
